// require('dotenv').config();
const key = 'c7205d6dbd49e2dd06f7'; //99b747d18cc3ad729486'; //process.env.REACT_APP_PINATA_KEY;
const secret = '81a13cc732b320f35b4d533a63e68f5497cf546dc10a92e17fe7795e43f60e23';//'fba24eb963aabce955b28a8388c4aaad80e0ca41bc701c0761e2050f9344c558'; //process.env.REACT_APP_PINATA_SECRET;

const axios = require('axios');
const FormData = require('form-data');

export const uploadJSONToIPFS = async(JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    //'https://beige-changing-camel-373.mypinata.cloud/ipfs/QmPyCYfL5oF79cfXjbt5cyr5hAZcyNrPNV9ytvUPdk8KT9?pinataGatewayToken=wrXKl_KbiIMtElpuxkH9mmWc0mY_NKCfU3MsEG0DduWT7-xzyAFoG5R418p8sEKk'
    //making axios POST request to Pinata ⬇️
    return axios 
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: 'c7205d6dbd49e2dd06f7',
                pinata_secret_api_key: '81a13cc732b320f35b4d533a63e68f5497cf546dc10a92e17fe7795e43f60e23',
            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataURL: "https://app.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};

export const uploadFileToIPFS = async(file) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    //making axios POST request to Pinata ⬇️
    
    let data = new FormData();
    data.append('file', file);

    const metadata = JSON.stringify({
        name: 'testname',
        keyvalues: {
            exampleKey: 'exampleValue'
        }
    });
    data.append('pinataMetadata', metadata);

    //pinataOptions are optional
    const pinataOptions = JSON.stringify({
        cidVersion: 0,
        customPinPolicy: {
            regions: [
                {
                    id: 'FRA1',
                    desiredReplicationCount: 1
                },
                {
                    id: 'NYC1',
                    desiredReplicationCount: 2
                }
            ]
        }
    });
    data.append('pinataOptions', pinataOptions);

    return axios 
        .post(url, data, {
            maxBodyLength: 'Infinity',
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: key,
                pinata_secret_api_key: secret,
            }
        })
        .then(function (response) {
            console.log("image uploaded", response.data.IpfsHash)
            //string str = response.data.IpfsHash;
            return {
               success: true,
               pinataURL: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};